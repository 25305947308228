<template>
  <section>
    <div class="content-header">
      <h2>Strategies for building a trauma-informed classroom community</h2>
    </div>
    <div class="content-wrapper">
      <p>People come into a classroom setting with incredibly different life experiences. Being trauma-informed means promoting a culture of safety, empowerment and healing in your classroom.</p>
      <p>You can read about the <a
          href="https://trauma-informed.ca/about-us/mtiec-trainings-and-webinars/trauma-informed-organizations-and-systems/principles"
          target="_blank"
        >principles of trauma-informed care</a> here.</p>
      <p>To get a sense of what that might look like, consider this case study.</p>
      <p style="margin: 0 5%;"><em>In class, when your course material features themes related to sexual violence, a student suddenly leaves your class visibly upset and crying, leaving all of her belongings behind. A few other students see that and snicker.</em> </p>
      <div class="carousel">
        <hooper
          ref="carousel_01"
          class="mb-4"
          :wheelControl="false"
        >
          <slide>
            <div class="slide">
              <div>
                <p><strong>Suggestions for immediate action:</strong></p>
                <ul class="disc">
                  <li>Acknowledge your own reactions and feelings (e.g., discomfort, surprise, desire to flee)</li>
                  <li>Confront the snickering</li>
                  <li>Follow up directly with the student impacted
                    <ul>
                      <li>Reflect on how you might do this if you know the student. What might be an approach if you do not know the student?</li>
                    </ul>
                  </li>
                  <li>Connect with campus sexual violence support for advice </li>
                  <li>Send a follow-up email to everyone in the class to address classroom expectations and offer resources (keep in mind that there may be more students who were impacted by the material)</li>
                </ul>
              </div>
            </div>
          </slide>

          <slide>
            <div class="slide">
              <div>
                <p><strong>Options for future planning:</strong></p>
                <ul class="disc">
                  <li>Consult resources that discuss building community, belonging and expectations:
                    <ul>
                      <li><a
                          href="https://uwaterloo.ca/centre-for-teaching-excellence/teaching-resources/teaching-tips/creating-positive-learning-environment/inclusivity-accessibility-and-motivation/building-community-large-classes"
                          target="_blank"
                        >University of Waterloo, Building Community in Large Classes </a>, which is licensed under <a href="https://creativecommons.org/licenses/by-nd/2.0/" target="_blank">CC BY-ND 4.0</a></li>
                      <li><a
                          href="https://www.sfu.ca/content/dam/sfu/healthycampuscommunity/wle2/brandedtools/Building%20a%20Healthy%20Class%20Environment%20Branded.pdf"
                          target="_blank"
                        >Simon Fraser University, Building a healthy class environment</a></li>
                    </ul>
                  </li>
                  <li>Prepare students for course material</li>
                  <li>Upload slides and course materials before class</li>
                  <li>Stick to the syllabus when possible so that students know what to expect</li>
                </ul>
              </div>
            </div>
          </slide>

          <hooper-navigation slot="hooper-addons">
            <img
              slot="hooper-prev"
              src="@/assets/img/_ui/svg/carousel-back.svg"
              alt="previous"
              srcset=""
            >
            <img
              slot="hooper-next"
              src="@/assets/img/_ui/svg/carousel-next.svg"
              alt="next"
              srcset=""
            >
          </hooper-navigation>

          <hooper-pagination slot="hooper-addons"></hooper-pagination>
        </hooper>
      </div>
      <p class="lm">Learn more</p>
      <ul>
        <li><a
            href="https://www.trentu.ca/teaching/node/389"
            target="_blank"
          >Trauma-informed teaching in remote courses, Trent University Centre for Teaching and Learning</a></li>
        <li><a
            href="https://www.trentu.ca/teaching/node/391"
            target="_blank"
          >Guidelines for talking about difficult topics in remote learning </a></li>
      </ul>

    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
import {
  Hooper,
  Slide,
  Navigation as HooperNavigation,
  Pagination as HooperPagination
} from 'hooper'
import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    // Accordion,
    Hooper,
    Slide,
    HooperNavigation,
    HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
